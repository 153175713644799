import React from 'react'
import { MDBCol } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faFax, faEnvelope, faInfoCircle, faGlobe } from '@fortawesome/pro-solid-svg-icons'
import Showdown from 'showdown'

class CardIconsOffices extends React.Component {
  render() {
    const converter = new Showdown.Converter() 
    return (
      <>
        <MDBCol lg="6" md="12" className="pb-30 d-flex">
          <div className="card flex-fill outline card-hover">
            <div
              className="card-body d-flex flex-row align-items-center"
              style={{ minHeight: '150px', maxHeight: '150px' }}
            >
              <div style={{ minWidth: '75px', maxWidth: '75px', }} >
                <GatsbyImage
                  image={this.props.image}
                  className="mr-1"
                  alt={this.props.alt}
                />
              </div>
              <div className="d-flex align-items-center">
                <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-small title-small pl-lg-4 pl-3">
                  {this.props.depttitle}
                </h3>
              </div>
            </div>

            <div className="card-body-prods">
              <ul className="fa-ul text-medium">
                {this.props.tel && (
                  <li className="pb-2">
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faPhoneAlt} className="ml-2 mr-5 va-m" />
                    </span>
                    {this.props.tel}
                  </li>
                )}

                {this.props.tel1 && (
                  <li className="pb-2">
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faPhoneAlt} className="ml-2 mr-5 va-m" />
                    </span>
                    {this.props.tel1}
                  </li>
                )}

                {this.props.fax && (
                  <li className="pb-2">
                    <span class="fa-li">
                      <FontAwesomeIcon icon={faFax} className="ml-2 mr-5 va-m" />
                    </span>
                    {this.props.fax}
                  </li>
                )}


                {this.props.emailsales && (
                  <li className="pb-2">
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faEnvelope} className="ml-2 mr-5 va-m" />
                    </span>
                    <a href={'mailto:' + this.props.emailsales} className="effect" rel="noopener" target="_blank">
                      Email sales
                    </a>
                  </li>
                )}                

                {this.props.emailsupport && (
                  <li className="pb-2">
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faEnvelope} className="ml-2 mr-5 va-m" />
                    </span>
                    <a href={'mailto:' + this.props.emailsupport} className="effect" rel="noopener" target="_blank">
                      Email support
                    </a>
                  </li>
                )}

                {this.props.portal && (
                  <li className="pb-2">
                    <span class="fa-li">
                      <FontAwesomeIcon icon={faGlobe} className="ml-2 mr-5 va-m" />
                    </span>
                    <a href={this.props.portal} className="effect" rel="noopener" target="_blank">
                      Customer support portal
                    </a>
                  </li>
                )}                
              </ul>
            </div>
          </div>
        </MDBCol>
      </>
    )
  }
}

export default CardIconsOffices
