import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import OfficeLocations from '../components/columnAlternatingOffices'
import OfficeDepartments from '../components/cardIconsOffices'
import FormMap from '../components/formMap'
import CardCompanies from '../components/cardCompanies'

const Contact = ({ data, location }) => {
  const heroImage = data.heroImage
  const post = data.markdownRemark
  const btnId = (location.state != null && location.state.btn != null) ? location.state.btn : "all"

  return (
    <>
      <Layout>
        <div id="sub-page">
          <SEO
            title="Contact Us"
            description="Want more information on Macro 4 solutions and services?  Please get in touch using the contact details or web form provided."
            url={data.site.siteMetadata.siteUrl + location.pathname} 
            image={data.site.siteMetadata.siteUrl + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}            
          />
          <Hero
            class="intro-65"
            gradientClass="mask d-flex justify-content-center align-items-center gradient"
            image={heroImage.childImageSharp.gatsbyImageData}
            alt={post.frontmatter.alttext}            
            title={post.frontmatter.title}
            subtitle={post.frontmatter.subtitle}
            type="root"            
          />
        </div>

        <main>
          <FormMap />

          <section className="bg-white" id="departments">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                  {post.frontmatter.departments.title}
                </h3>
                {post.frontmatter.departments.contactdeets && (
                  <MDBRow>
                    {post.frontmatter.departments.contactdeets.map( details => (
                      <OfficeDepartments
                        depttitle={details.depttitle}
                        tel={details.tel}
                        tel1={details.tel1}
                        fax={details.fax}
                        emailsales={details.emailsales}
                        emailsupport={details.emailsupport}
                        portal={details.portal}
                        suptext={details.suptext}
                        image={details.image.childImageSharp.gatsbyImageData}
                        alt={details.alttext}
                      />
                    ))}
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>

          <section className="bg-light-blue" id="locations">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large pb-5">
                  {post.frontmatter.locations.title}
                </h3>
                {post.frontmatter.locations.offices.map(
                  (officelocations, index) => (
                    <OfficeLocations
                      key={index}
                      office={officelocations.office}
                      address={officelocations.address}
                      lat={officelocations.lat}
                      lng={officelocations.lng}
                      placement={officelocations.placement}
                    />
                  )
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>

          {post.frontmatter.section.map((sections, index) => (
            <section className="bg-white"  id="global" key={index}>
              <MDBContainer>
                <MDBRow>
                  <MDBCol md="12" className="pr-5 pb-5">
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-4">
                      {sections.title}                                         
                    </h2>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    {sections.feature && (
                      <CardCompanies data={sections.feature} btnId={btnId}/>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          ))}
        </main>
      </Layout>
    </>
  )
}
export default Contact


export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
    markdownRemark(frontmatter: { name: { eq: "contact" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        alttext
        image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
        locations {
          title
          offices {
            office
            address
            lat
            lng
            placement
          }
        }
        departments {
          title
          contactdeets {
            depttitle
            tel
            tel1
            fax
            emailsales
            emailsupport
            portal
            alttext
            image {
              childImageSharp {
                gatsbyImageData(width: 85, height: 85, quality: 90)
              }
            }
          }
        }
        section {
          title
          subtitle
          description
          feature {
            title
            subtitle
            description
            tel
            email
            icon
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            link
            linktext
            placement  
          }
        }        
      }
      html
    }
    heroImage: file(name: { eq: "contact-us" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`